import React from 'react';
import './AboutUs.css'; // Ensure you import your CSS file

function AboutUs() {
  return (
    <div className="about-us-page">
      <div className="banner">
      <div className="banner-overlay"></div>
        <h2>ABOUT US</h2>
      </div>

      <div className="section white-background">
      <p>
        At Markap, we are artisans of comfort and style, masters in the craft of creating premium furniture that stands the test of time. Our journey is marked by a passion for innovation and excellence in every piece that graces your spaces. With a keen eye for detail and dedication to quality, we ensure that every client receives a product that not only meets but exceeds their expectations.
      </p>

      </div>

      <div className="photo-background">
        <div className="overlay"></div>
          <div className="section">
            <p>
              Our mission is to revolutionize the world of furniture by blending aesthetics with practicality. We strive to create pieces that are not just furniture, but a statement of artistry and a reflection of our clients' vision. We are committed to sustainability, ergonomics, and innovative design, pushing the boundaries to redefine the standards of furniture craftsmanship.
            </p>
          </div>
      </div>

      <div className="section white-background">
      <p>
        Since our inception, Markap has been at the forefront of furniture design, building a legacy that intertwines with the growth of our prestigious clientele. Our story is enriched by collaborations with renowned names like Auchan, Penny, La doi pasi, Farmaciile Dona, Hectar, and many others. Each partnership has been a stepping stone, propelling us towards new horizons and inspiring us to leave a mark in the annals of furniture history.
      </p>
      </div>
    </div>
  );
}

export default AboutUs;
