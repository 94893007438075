import Reac, { useState, useEffect } from 'react';
import './HomePage.css';
import { useNavigate } from 'react-router-dom';

function HomePage() {

  let navigate = useNavigate(); 
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  const navigateToOurWork = () => {
    navigate('/ourwork');
  };

  return (
    <div className="homepage">
      <div className="overlay-home">
        {/* Content for your homepage */}
        <div className="text-container">
          <div className="text-square left first-text" onClick={navigateToOurWork} style={{ '--order': 1 }}>
            {isMobile ? 
              <p>Explore Markap's unique furniture on-the-go.</p> :
              <p>Welcome to Markap, where the art of craftsmanship meets modern living.</p>
            }
          </div>
          <div className="text-square right" style={{ '--order': 2 }}>
            <p>We're not just creating furniture; we're curating experiences, designing spaces where ideas flourish and moments are cherished.</p>
          </div>
          <div className="text-square left last-message" style={{ '--order': 3 }}>
            <p>Step into a world where each piece tells a story, crafted with the utmost care and designed with an unwavering dedication to beauty and functionality. Let's build a space that's uniquely yours – inspiring, comfortable, and timeless.</p>  
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
