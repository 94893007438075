import React from 'react';
import './CareerPage.css'; // Ensure you import your CSS file

function CareersPage() {
  return (
    <div className="career-page">
      <div className="banner">
        <div className="banner-overlay"></div>
        <h2>JOIN OUR TEAM</h2>
      </div>
      <div className="section-overlay">
      <section className="career-item">
        <img src="/images/CareerPage/FurnitureDeveloper.png" alt="Job Opening 1"/>
        <div className="career-text">
        <h3>Join Our Innovative Furniture Design Team!</h3>
        <h4>Position: Furniture Developer/Designer</h4>
        <p>Are you passionate about furniture design and innovation? We are seeking a creative and skilled Furniture Developer/Designer to join our dynamic team. In this role, you'll have the opportunity to bring unique furniture designs to life, from initial concept sketches to final production.</p>
        <h5>Responsibilities:</h5>
        <ul>
            <li>Create and develop furniture design concepts.</li>
            <li>Prepare detailed sketches and design specifications.</li>
            <li>Collaborate with production teams to ensure design feasibility.</li>
            <li>Stay updated with the latest trends in furniture design and materials.</li>
        </ul>
        <h5>Qualifications:</h5>
        <ul>
            <li>Degree in Industrial Design, Furniture Design, or a related field.</li>
            <li>Proven experience in furniture design.</li>
            <li>Proficiency in design software (e.g., AutoCAD, SketchUp).</li>
            <li>Excellent sketching and prototyping skills.</li>
            <li>Strong creative and problem-solving abilities.</li>
        </ul>
        <p>Join us and make an impact through your designs in the world of contemporary furniture!</p>
    </div>
      </section>

      <section className="career-item reverse">
            <img src="/images/CareerPage/assistant_manager.png" alt="Assistant Manager at Work" className="career-image"/>
            <div className="career-text">
                <h3>Exciting Opportunity for an Assistant Manager!</h3>
                <h4>Position: Assistant Manager</h4>
                <p>Join our team as an Assistant Manager and play a key role in our company's success. You'll be assisting in managing operations, supervising staff, and ensuring customer satisfaction.</p>
                <h5>Responsibilities:</h5>
                <ul>
                    <li>Assist in overseeing daily business operations.</li>
                    <li>Support team members and manage resources effectively.</li>
                    <li>Engage in strategic planning and goal-setting.</li>
                    <li>Ensure compliance with company policies and industry regulations.</li>
                </ul>
                <h5>Qualifications:</h5>
                <ul>
                    <li>Bachelor's degree in Business Administration or related field.</li>
                    <li>Proven experience in a managerial role.</li>
                    <li>Strong leadership and decision-making skills.</li>
                    <li>Excellent communication and interpersonal abilities.</li>
                    <li>Ability to work under pressure in a fast-paced environment.</li>
                </ul>
                <p>Become a pivotal part of our management team and contribute to our growth and success!</p>
            </div>
       </section>



       <section className="career-item">
            <img src="/images/CareerPage/cnc_machine_operator.png" alt="CNC Machine Operator at Work" className="career-image"/>
            <div className="career-text">
                <h3>Join as a CNC Machine Operator!</h3>
                <h4>Position: CNC Machine Operator</h4>
                <p>Be a part of our manufacturing team as a CNC Machine Operator. You will handle advanced machinery to create parts and tools from metal and other materials.</p>
                <h5>Responsibilities:</h5>
                <ul>
                    <li>Operate CNC machines to perform tasks such as drilling, grinding, milling, etc.</li>
                    <li>Understand specifications of the task at hand and the desired result.</li>
                    <li>Check and maintain machinery daily to ensure functionality.</li>
                </ul>
                <h5>Qualifications:</h5>
                <ul>
                    <li>Proven experience as CNC operator.</li>
                    <li>Skill in operating CNC machinery and tooling as well as precision measurement tools.</li>
                    <li>Ability to read and interpret mechanical documents and drawings.</li>
                </ul>
                <p>Join our team and advance your career in advanced manufacturing!</p>
            </div>
        </section>

        <section className="career-item reverse">
            <img src="/images/CareerPage/unskilledWorker.png" alt="Unskilled Worker at Work" className="career-image"/>
            <div className="career-text">
                <h3>We're Hiring: Unskilled Worker</h3>
                <h4>Position: Unskilled Worker</h4>
                <p>Join our workforce as an Unskilled Worker. This role is perfect for those who are eager to start their career in the industry and learn new skills.</p>
                <h5>Responsibilities:</h5>
                <ul>
                    <li>Assist in various tasks around the production area.</li>
                    <li>Follow all health and safety regulations.</li>
                    <li>Support other team members in achieving production goals.</li>
                </ul>
                <h5>Qualifications:</h5>
                <ul>
                    <li>No previous experience required.</li>
                    <li>Willingness to learn and work in a team.</li>
                    <li>Basic understanding of health and safety practices.</li>
                </ul>
                <p>Start your journey with us and grow your skills!</p>
            </div>
        </section>
        </div>
    </div>
  );
}

export default CareersPage;
