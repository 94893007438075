  import React, { useState } from 'react';
  import { Link } from 'react-router-dom';
  import './MenuBar.css';

  function MenuBar() {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
      setMenuOpen(!menuOpen);
    };

    return (
      <nav className="menu-bar">
        <div className="logo">
          <Link to="/"><img src="../../../images/Logo/MarkapLogo.png" alt="Logo" /></Link> 
        </div>
        <div className="hamburger" onClick={toggleMenu}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <div className={`menu-overlay ${menuOpen ? 'open' : ''}`}>
          <ul className="menu-items">
            <li><Link to="/" onClick={toggleMenu}>HOME</Link></li>
            <li><Link to="/ourwork" onClick={toggleMenu}>OUR WORK</Link></li>
            <li><a href="http://shop.markap.ro" target="_blank" rel="noopener noreferrer" onClick={toggleMenu}>OUR SHOP</a></li>
            <li><Link to="/career" onClick={toggleMenu}>CAREER</Link></li>
            <li><Link to="/aboutus" onClick={toggleMenu}>ABOUT US</Link></li>
            <li><Link to="/contact" onClick={toggleMenu}>CONTACT</Link></li>
            {/* Add other links as needed */}
          </ul>
        </div>
      </nav>
    );
  }

  export default MenuBar;
