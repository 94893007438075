import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MenuBar from './Menu/MenuBar/MenuBar';
import HomePage from './Pages/HomePage/HomePage';
import ProjectsPage from './Pages/ProjectsPage/ProjectPage';
import AboutUs from './Pages/AboutUsPage/AboutUs';
import ContactPage from './Pages/ContactPage/ContactPage';
import Footer from './Footer/Footer';
import CareersPage from './Pages/CareerPage/CareerPage';

function App() {
  return (
    <Router>
      <div className="App">
        <MenuBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/ourwork" element={<ProjectsPage />} /> 
          <Route path="/aboutus" element={<AboutUs />} /> 
          <Route path="/career" element={<CareersPage />} /> 
          <Route path="/contact" element={<ContactPage />} /> 
        </Routes>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
