import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import './ContactPage.css'; // Ensure this path is correct for your CSS file

function ContactPage() {

  const form = useRef();
  const [submitted, setSubmitted] = useState(false);

  function sendEmail(e) {
    e.preventDefault();

    // Use emailjs.sendForm() to send the email
    emailjs.sendForm('service_wwh6av5', 'template_qefeo44', e.target, 'sIgedgIeVFkZu589z')
      .then((result) => {
          setSubmitted(true); 
          e.target.reset();
      }, (error) => {
          console.log('Failed to send email.', error.text);
          // Handle errors here, for example, showing an error message to the user
      });
  }

  return (
    <div className="wrapper-contact">
      <div className="banner">
        <div className="banner-overlay"></div>
        <h2>CONTACT US</h2>
      </div>
      <div className="inner-contact">
        <p className="contact-description">
          We're here to help you create the perfect space for your business...
          {/* Rest of your description */}
        </p>
        <form onSubmit={sendEmail}>
          <label className="form-group-contact">
            <input type="text" name="user_name" className="form-control-contact" required />
            <span>Your Name</span>
            <span className="border"></span>
          </label>
          <label className="form-group-contact">
            <input type="email" name="user_email" className="form-control-contact" required />
            <span>Your Mail</span>
            <span className="border"></span>
          </label>
          <label className="form-group-contact">
            <textarea name="message" className="form-control-contact form-message" required></textarea>
            <span>Your Message</span>
            <span className="border border-message "></span>
          </label>
          <button type="submit">Submit <i className="zmdi zmdi-arrow-right"></i></button>
        </form>
        {submitted && <p className="success-message">Thank you! Your message has been sent.</p>}
      </div>
    </div>
  );
}

export default ContactPage;
