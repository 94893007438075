import React from 'react';
import './Footer.css'; // Make sure to import the CSS file

function Footer() {
  return (
    <div className="container my-5">
      <footer className="text-center text-lg-start text-white" style={{ backgroundColor: "#1b1b1b" }}>
        <div className="container p-4 pb-0">
          <section>
            <div className="row">
              {/* Company Info */}
              <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3 description-section">
                <h6 className="text-uppercase mb-4 font-weight-bold">Markap Business Solutions</h6>
                <p>Crafting spaces with elegance and precision, our furniture is designed to elevate your environment. We accelerate your company's image with bespoke pieces that blend innovative design with unmatched functionality. Transform your workspace into a statement of professionalism and style.</p>
              </div>

              {/* Products */}
              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3 materials-section">
                <h6 className="text-uppercase mb-4 font-weight-bold">Products</h6>
                <p><a href="#!" className="text-white">Plexiglass</a></p>
                <p><a href="#!" className="text-white">Furniture</a></p>
                <p><a href="#!" className="text-white">Glass</a></p>
                <p><a href="#!" className="text-white">Feronery</a></p>
              </div>

              {/* Contact Info */}
              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">Contact</h6>
                <p><i className="fas fa-home mr-3"></i> Ilfov, Manolache, Strada Căminului 82C</p>
                <p><i className="fas fa-envelope mr-3"></i> contact@markap.ro</p>
                <p><i className="fas fa-phone mr-3"></i> +40 0720 746 470</p>
                <p><i className="fas fa-phone mr-3"></i> +40 0721 212 212</p>
              </div>

              {/* Social Media Links */}
              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3 social-section">
                <h6 className="text-uppercase mb-4 font-weight-bold">Follow us</h6>
                {/* Facebook */}
                <a className="btn btn-primary btn-floating m-1" style={{ backgroundColor: "#3b5998" }} href="#!" role="button"><i className="fab fa-facebook-f"></i></a>
                {/* Instagram */}
                <a className="btn btn-primary btn-floating m-1" style={{ backgroundColor: "#ac2bac" }} href="#!" role="button"><i className="fab fa-instagram"></i></a>
                {/* Linkedin */}
                <a className="btn btn-primary btn-floating m-1" style={{ backgroundColor: "#0082ca" }} href="#!" role="button"><i className="fab fa-linkedin-in"></i></a>
              </div>
            </div>
          </section>
        </div>

        <div className="text-center p-3" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
          © 2024 Copyright: Markap Business Solutions SRL
        </div>
      </footer>
    </div>
  );
}

export default Footer;
