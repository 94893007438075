import React,  { useState, useEffect }  from 'react';
import './ProjectPage.css'; // Ensure you import your CSS file

function ProjectPage() {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  const column1Images = ['/images/ProjectsPage/Viena/Viena.PNG',
   '/images/ProjectsPage/Lidas/Lidas.PNG',
   '/images/ProjectsPage/Hectar/HARO2.jpg',];
  const column2Images = [
   '/images/ProjectsPage/Hectar/HARO1.jpg',
   '/images/ProjectsPage/Leroy/Leroy Merlin.PNG',
   '/images/ProjectsPage/Viena/Viena1.jpg',];
  const column3Images = ['/images/ProjectsPage/Hectar/HARO3.jpg',
   '/images/ProjectsPage/Viena/Viena2.jpg',
   '/images/ProjectsPage/Hectar/Hectarul.PNG',];

  return (
    <div className="project-page">
      <div className="banner">
        <div className="banner-overlay"></div>
        {isMobile ?
          <h2>SOLUTIONS</h2> :
          <h2>PROFESSIONAL BUSINESS AND COMMERCIAL FURNITURE SOLUTIONS</h2>
        }
      </div>
      <div className="photo-columns">
        <div className="column">
          {column1Images.map((img, index) => <img key={index} src={img} alt={`Leroy ${index}`}   className="project-image" />)}
        </div>
        <div className="column">
          {column2Images.map((img, index) => <img key={index} src={img} alt={`Viena ${index}`} className="project-image" />)}
        </div>
        <div className="column mobile-only">
          {column3Images.map((img, index) => <img key={index} src={img} alt={`Hectar ${index}`} className="project-image" />)}
        </div>
      </div>
    </div>
  );
}

export default ProjectPage;
